$base-spacing: 16px;

$yellow: #dcc243;
$red: #dc6443;
$green: #43dc99;
$blue: #43b0dc;
$purple: #8f43dc;
$pink: #dc4399;

@import 'tachyons-lite';
@import 'global';